import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import "./reuseables.css";
import usePreventDoubleClick from "./usePreventDoubleClick";
const { postTimePunch } = require("../src/fetches/fetch");
let api = require("./apiConfig");
const io = require("socket.io-client");
const { socket, socket_init } = require("./socketConnect");

api = api.api;

function Login() {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const handleLogin = async () => {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;

    try {
      const response = await fetch(`${api}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: username, password: password }),
      });
      if (response.status === 500) {
        return setErrorMessage("Wrong Username or Password!");
      }
      const data = await response.json();
      Cookies.set("userid", data.data.id);
      Cookies.set("jwt", data.token);
      postTimePunch(Cookies.get("userid"), new Date(), "login");
      window.location.href = "/lead-management";
    } catch (error) {
      console.error(error);
      return setErrorMessage("Down for maintenance!");
    }
  };
  const editInputFields = () => {
    setErrorMessage("");
  };
  const handleEnterButton = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className="center-div-flex">
      <div className="flex-container-el ">
        <h2>Login</h2>
        <input
          type="text"
          className="regularTextInputLogin max-width margin-top-10px"
          autoComplete="off"
          placeholder="Username"
          ref={usernameRef}
          onKeyDown={handleEnterButton}
          onChange={editInputFields}
        />
        <input
          type="password"
          className="regularTextInputLogin max-width margin-top-10px"
          autoComplete="off"
          placeholder="Password"
          ref={passwordRef}
          onKeyDown={handleEnterButton}
          onChange={editInputFields}
        />
        <button
          type="submit"
          className="green-submit-btnLogin margin-top-10px max-width"
          onClick={() => preventDoubleClick(handleLogin)}
          disabled={isButtonDisabled}
        >
          Login🔐
        </button>
        <p data-testid="error_message" className="margin-top-10px color-red">
          {errorMessage}
        </p>
      </div>
    </div>
  );
}

export default Login;
