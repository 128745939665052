import React from "react";
import Cookies from 'js-cookie'
import { useSelector, useDispatch } from "react-redux";
import { changePageState } from "../redux/pageNavigator.js";
export default function SharedLeadPopup({popup_data, setShow, set_popup_data, setCurrentHouseholdId}) {

const dispatch = useDispatch();
  return <>
         <div className="flex-container-el padding-10px">
                <h3 className="padding-10px">A lead was shared/ reassigned amongst you </h3>

                <button
                  type="button"
                  className="button-default green-submit-btn margin-top-20px max-width padding-10px"
                  onClick = { () => {
                    Cookies.set('currentHouseholdId', popup_data?.household_id[0]); 
                    setCurrentHouseholdId(popup_data?.household_id[0])
                    dispatch(changePageState('lead'));
                    set_popup_data({})
                    setShow(false);
                  }} 
                 
                >
                    View Lead
                </button>
              </div>
  </>;
}
