import React from "react";
import { createPortal } from "react-dom";
import "../src/notifications.css";

function Notification_popup_container({ show, setShow, popup}) {

  return (
    show && (
      <div className="backdrop ">
        {createPortal(
          <div className="center-popup">
            <div className="background-color-full-dark border-radius-10px  popup z-index ">
              <div className="black-background  text-align-right border-radius-top-10px ">
                <button
                  type="button"
                  className="popup-exit-button"
                  onClick = { () => setShow(false)}
               
                >
                  ❌
                </button>
              </div>
                {popup}
            </div>
          </div>,
          document.body
        )}
      </div>
    )
  );
}

export default Notification_popup_container;
