import { useState } from 'react';

function usePreventDoubleClick() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const preventDoubleClick = (callback, delay = 5000) => {
    // Disable the button
    setIsButtonDisabled(true);

    // Call the callback function (e.g., handleLogin)
    callback();

    // Re-enable the button after the specified delay
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, delay); // Default to 2 seconds or adjust as needed
  };

  return {
    isButtonDisabled,
    preventDoubleClick,
  };
}

export default usePreventDoubleClick;
