import React, {useRef} from "react";
const { editTimePunchApi } = require('../fetches/fetch.js')
function EditTimeTablePopup({
  timePunches,
  setTogglePopup,
  daysOfWeek,
  editTimePunch,
  returnTimePunches,
  handleTimeTableRowClick,
  user
}) {
    const hours = useRef();
    const minutes = useRef(); 
    const period = useRef();

    const handleCreateDateTimeObj = () => { 
        const date = new Date(editTimePunch.timePunch);
        date.setHours(hours.current.value ); 
        date.setMinutes(minutes.current.value);
       /*Convert to am or pm based on selected value */
        if(period.current.value === 'PM'){ 
            date.setHours(date.getHours() + 12);
        }
        return date.toISOString();
    }

    const handleEditTimePunch = async(timesheet_ID) => { 
        const date = handleCreateDateTimeObj(); 
        const response = await editTimePunchApi(date, timesheet_ID);
        if(response.status === 500){ 
            return alert('error updating timepunch'); 

        }
        await returnTimePunches();
        await  handleTimeTableRowClick(date, user)
        return setTogglePopup(false);


    }



  return (
    <div>
      <div
        className="background-color-full-dark border-radius-10px z-index  word-wrap "
        id="household-driver-crud-ops"
      >
        <div className="black-background  text-align-right border-radius-top-10px z-index max-wdith flex-across-space-between align-items-center">
          <span className="black-background">
            {editTimePunch &&

              daysOfWeek[new Date(editTimePunch?.timePunch).getDay()] +
                ", " +
                new Date(editTimePunch?.timePunch).toLocaleTimeString()}
          </span>
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setTogglePopup(false)}
          >
            ❌
          </button>
        </div>
       
        <div className=" padding-10px ">
          <h3 className="margin-bottom-10">Edit Time Punch</h3>
          <div className="max-width flex-across-space-between align-items-center margin-bottom-10 ">
            <select className="regularTextInput " ref = {hours}>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 13 ||
                  new Date(editTimePunch.timePunch).getHours() === 1
                }
              >
                1
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 14 ||
                  new Date(editTimePunch.timePunch).getHours() === 2
                }
              >
                2
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 15 ||
                  new Date(editTimePunch.timePunch).getHours() === 3
                }
              >
                3
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 16 ||
                  new Date(editTimePunch.timePunch).getHours() === 4
                }
              >
                4
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 17 ||
                  new Date(editTimePunch.timePunch).getHours() === 5
                }
              >
                5
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 18 ||
                  new Date(editTimePunch.timePunch).getHours() === 6
                }
              >
                6
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 19 ||
                  new Date(editTimePunch.timePunch).getHours() === 7
                }
              >
                7
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 20 ||
                  new Date(editTimePunch.timePunch).getHours() === 8
                }
              >
                8
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 21 ||
                  new Date(editTimePunch.timePunch).getHours() === 9
                }
              >
                9
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 22 ||
                  new Date(editTimePunch.timePunch).getHours() === 10
                }
              >
                10
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 23 ||
                  new Date(editTimePunch.timePunch).getHours() === 11
                }
              >
                11
              </option>
              <option
                selected={
                  new Date(editTimePunch.timePunch).getHours() === 24 ||
                  new Date(editTimePunch.timePunch).getHours() === 12
                }
              >
                12
              </option>
            </select>
            :
            <input
              type="text"
              className="regularTextInput width-20px"
              maxLength={"2"}
              ref={minutes}
              defaultValue={
                new Date(editTimePunch.timePunch).getMinutes() < 10
                  ? "0" + new Date(editTimePunch.timePunch).getMinutes()
                  : new Date(editTimePunch.timePunch).getMinutes()
              }
            />
            <select className="regularTextInput " ref = {period}>
              <option value = 'AM' selected = {editTimePunch &&  new Date(editTimePunch.timePunch).getHours() < 12}>AM</option>
              <option value = 'PM' selected = { editTimePunch && new Date(editTimePunch.timePunch).getHours() > 12} >PM</option>
            </select>
          </div>
          <button className="green-submit-btn max-width" onClick = { () => handleEditTimePunch(editTimePunch.timePunchID) }>Change Time</button>
        </div>
      </div>
    </div>
  );
}

export default EditTimeTablePopup;
