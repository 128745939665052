import React, { useEffect, useState, useRef } from "react";
import { insurance_companies } from "../jsonData/insurance_companies.js";
import { policy_types } from "../jsonData/policy_types";
import usePreventDoubleClick from "../usePreventDoubleClick";
const {
  getPoliciesByUserId,
  editPolicyAttribute,
  getUnpaidCommission,
} = require("../fetches/fetch.js");
function CommissionPolicyPopup({
  setToggleCommissionPopup,
  userIdAndFullName,
  currentUserID,
  setCurrentUserID,
}) {
  const [policies, setPolicies] = useState();
  const [premTotal, setPremTotal] = useState();
  const [unpaidCommission, setUnpaidCommission] = useState();
  const [toDate, setToDate] = useState(() => {
    const endOfMonth = new Date();
    endOfMonth.setMonth(endOfMonth.getMonth()); // Move to the next month
    endOfMonth.setDate(0); // Set to the last day of the previous month
    endOfMonth.setHours(23, 59, 59, 999); // Set time to the end of the day

    return endOfMonth;
  });
  const [fromDate, setFromDate] = useState(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(startOfMonth.getMonth() - 1);
    startOfMonth.setDate(1); // Set to the first day of the month
    startOfMonth.setHours(0, 0, 0, 0);
    return startOfMonth;
  });
  const [sales_type, set_sales_type] = useState("New Business");
  const [computedItemsTotal, setComputedItemsTotal] = useState();
  const [numberOfPoliciesTotal, setNumberOfPoliciesTotal] = useState();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleGetPolicies = async (user_id, fromDate, toDate, sales_type) => {
    const response = await getPoliciesByUserId(
      user_id,
      fromDate,
      toDate,
      sales_type,
      true
    );
    const data = await response.json();
    setPolicies(data);
  };

  const handleGetUnpaidComission = async (fromDate) => {
    const response = await getUnpaidCommission(fromDate);
    const data = await response.json();
    setUnpaidCommission(data);
  };

  useEffect(() => {
    handleGetUnpaidComission(fromDate);
  }, []);
  useEffect(() => {
    handleGetPolicies(currentUserID, fromDate, toDate, sales_type);
  }, [toDate, fromDate, sales_type, currentUserID]);

  useEffect(() => {
    computeTotal();
  }, [policies]);

  const computeTotal = async () => {
    if (policies) {
      setPremTotal(
        policies
          .reduce((total, policy) => total + (policy.total_prem || 0), 0)
          .toFixed(2)
      );

      setComputedItemsTotal(
        policies.reduce((total, policy) => total + (policy.items || 0), 0)
      );

      setNumberOfPoliciesTotal(policies.length);
    }
  };
  const handleAttributeUpdate = async (policy_id, attribute, data) => {
    const response = await editPolicyAttribute(policy_id[0], attribute, data);
    if (response.status === 200) {
      return await handleGetPolicies(
        currentUserID,
        fromDate,
        toDate,
        sales_type
      );
    }
  };

  return (
    <>
      <div className="background-color-full-dark border-radius-10px popup z-index ">
        <div className="black-background  flex-across-space-between align-itmes-center  border-radius-top-10px  ">
          <h3 className="black-background">
            Commissions Production Month:{" "}
            {new Date().getMonth() - 1  === -1 ? "December" + " " : monthNames[new Date().getMonth() - 1]  }
         
          </h3>
          <h3 className="black-background margin-left-neg-20">   Compensation Month: {monthNames[new Date().getMonth()]}</h3>

          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setToggleCommissionPopup(false)}
          >
            ❌
          </button>
        </div>

        <div className=" padding-1px light-dark-background height-80vh  ">
          <div className="flex-across light-dark-background align-items-center flex-across-space-between max-width">
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Comm Prem Total:</h3>{" "}
              </p>
              <p className="light-dark-background">${premTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Items Total:</h3>
              </p>
              <p className="light-dark-background">{computedItemsTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Number Of Policies:</h3>
              </p>
              <p className="light-dark-background">{numberOfPoliciesTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">
                  Base Commission Total:
                </h3>
              </p>
              <p className="light-dark-background">
                ${premTotal && (premTotal * 0.04).toFixed(2)}
              </p>
            </div>
            <div className="light-dark-background display-flex flex-column-no-ho-centered  ">
              <h3 className="light-dark-background ">
                <span className="light-dark-background ">From: </span>
              </h3>
              <input
                className="light-dark-background  "
                type="date"
                name="from "
                defaultValue={fromDate.toISOString().split("T")[0]}
                onChange={(e) => setFromDate(new Date(e.target.value))}
              />
            </div>
            <div className="light-dark-background display-flex flex-column-no-ho-centered  ">
              <b className="light-dark-background">
                <span className="light-dark-background margin-left-5 ">
                  To:
                </span>
              </b>
              <input
                className="light-dark-background margin-left-5"
                type="date"
                name="to"
                defaultValue={toDate.toISOString().split("T")[0]}
                onChange={(e) => setToDate(new Date(e.target.value))}
              />
            </div>
            <div>
              <h3 className="light-dark-background ">
                <label className="light-dark-background ">Sales Type</label>
              </h3>
              <select
                className="light-dark-background green-submit-btn padding-left-none"
                defaultValue={sales_type}
                onClick={(e) => set_sales_type(e.target.value)}
              >
                <option value="">All</option>
                <option value="New Business">New Business</option>
                <option value="Rewrite">Rewrite</option>
                <option value="Cross Sale">Cross Sale</option>
                <option value="Cancel Rewrite">Cancel Rewrite</option>
              </select>
            </div>
            <div className="light-dark-background ">
              <h3 className="light-dark-background ">Users</h3>
              <select
                className="light-dark-background  green-submit-btn padding-left-none"
                onClick={(e) => setCurrentUserID(e.target.value)}
              >
                <option value={""} onClick={() => {}}>
                  All Users
                </option>
                {userIdAndFullName &&
                  Object.keys(userIdAndFullName).map((id) => (
                    <option value={id} selected={id === currentUserID}>
                      {userIdAndFullName[id]}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="commissions-popup margin-top-10px font-small">
            <table className="th-style ">
              <thead className="max-width">
                <th>Primary Driver Name</th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Date Bound</th>
                <th>Producer</th>
                <th>Company</th>
                <th>Effective Date</th>
                <th>Sales Type</th>
                <th>Status</th>
                <th>Comm Prem</th>
                <th>MVR Fee</th>
                <th>Clue Fee</th>
                <th>Line of Business</th>
                <th>Location</th>
                <th>Comp. Status</th>
                <th>Multiline</th>
                <th>Monoline</th>
                <th>UW Action</th>
              </thead>
              <tbody id="search-results_table_body">
                {policies &&
                  policies.map((policy) => (
                    <>
                      <tr>
                        <td>{policy.full_name}</td>
                        <td>{policy.policy_id_two}</td>
                        <td>
                          <select
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "policy_type",
                                e.target.value
                              )
                            }
                          >
                            {policy_types &&
                              policy_types.map((policy_type) => (
                                <option value={policy_type}>
                                  {policy_type}
                                </option>
                              ))}
                            <option value={policy.policy_type} selected>
                              {" "}
                              {policy.policy_type}
                            </option>
                          </select>
                        </td>
                        <td>
                          {" "}
                          {new Date(policy.date_created[0]).toLocaleDateString(
                            "en-US",
                            { timeZone: "UTC" }
                          )}
                        </td>
                        <td>
                          {userIdAndFullName && (
                            <select
                              className="max-width"
                              onChange={(e) =>
                                handleAttributeUpdate(
                                  policy.policy_id,
                                  "user_created_id",
                                  e.target.value
                                )
                              }
                            >
                              {Object.keys(userIdAndFullName).map((id) => (
                                <option value={id}>
                                  {userIdAndFullName[id]}
                                </option>
                              ))}
                              <option selected value={policy.user_created_id}>
                                {userIdAndFullName[policy.user_created_id]}
                              </option>
                            </select>
                          )}
                        </td>
                        <td>
                          <select
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "company",
                                e.target.value
                              )
                            }
                          >
                            {insurance_companies &&
                              insurance_companies.map((company_name) => (
                                <option value={company_name}>
                                  {company_name}
                                </option>
                              ))}
                            <option selected value={policy.company}>
                              {policy.company}{" "}
                            </option>
                          </select>
                        </td>
                        <td>
                          {new Date(policy.effective_date).toLocaleDateString(
                            "en-US",
                            { timeZone: "UTC" }
                          )}
                        </td>
                        <td>
                          <select
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "sales_type",
                                e.target.value
                              )
                            }
                          >
                            <option value={policy.sales_type}>
                              {policy.sales_type}
                            </option>
                            <option value="New Business">New Business</option>
                            <option value="Rewrite">Rewrite</option>
                            <option value="Cancel Rewrite">
                              Cancel Rewrite
                            </option>
                            <option value="Cross Sale">Cross Sale</option>
                          </select>
                        </td>
                        <td>
                          <select
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "status",
                                e.target.value
                              )
                            }
                          >
                            <option value="Active">Active</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Pending Cancel">
                              Pending Cancel
                            </option>
                            <option value="Terminated">Terminated</option>
                            <option selected value={policy.status[0]}>
                              {policy?.status[0]}
                            </option>
                          </select>
                        </td>
                        <td>
                          <div className="flex align-items-center light-dark-background">
                            <input
                              type="text"
                              placeholder={
                                policy.commissionable_premium
                                  ? "$" +
                                    policy.commissionable_premium.toFixed(2)
                                  : null
                              }
                              className="max-width box-border-2"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  // Correctly access the key property
                                  handleAttributeUpdate(
                                    policy.policy_id,
                                    "commissionable_premium",
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          {policy.company === "National General"
                            ? "$" + policy.number_of_drivers * 10
                            : "No Fee"}
                        </td>
                        <td>
                          {policy.company === "National General"
                            ? "$3 "
                            : "No Fee"}
                        </td>
                        <td>
                          {policy.organization_household_id !== null
                            ? "Commercial"
                            : "Personal"}{" "}
                        </td>
                        <td>{policy.address_line_2}</td>
                        <td>
                          {policy?.status[0] === "Active"
                            ? "Ready To Pay"
                            : "Do not pay"}
                        </td>
                        <td>{policy["multiline "] === true ? "1" : "0"}</td>
                        <td>{policy.monoline === true ? "1" : "0"}</td>
                        <td>
                          <select>
                            <option
                              selected={policy.uw_action_id !== null}
                              value="Yes"
                            >
                              Yes
                            </option>
                            <option
                              selected={policy.uw_action_id === null}
                              value="No"
                            >
                              No
                            </option>
                          </select>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
          <label className="light-dark-background margin-top-10px">
            <h3 className="light-dark-background">Past Unpaid Commission</h3>
          </label>
          <div className="commissions-popup margin-top-10px font-small ">
            <table className="th-style ">
              <thead>
                <th>Primary Driver Name</th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Date Bound</th>
                <th>Producer</th>
                <th>Company</th>
                <th>Effective Date</th>
                <th>Sales Type</th>
                <th>Status</th>
                <th>Comm Prem</th>
                <th>MVR Fee</th>
                <th>Clue Fee</th>
                <th>Line of Business</th>
                <th>Location</th>
                <th>Comp. Status</th>
                <th>Multiline</th>
                <th>Monoline</th>
              </thead>

              <tbody id="search-results_table_body">
                {unpaidCommission &&
                  unpaidCommission.map((commission) => (
                    <tr className="cursor-pointer hover-secondary-color">
                      <td>{commission.full_name}</td>
                      <td>{commission.policy_id_two}</td>

                      <td>
                        <select className="max-width" onChange = { (e) => handleAttributeUpdate(commission.policy_id, 'policy_type', e.target.value)}>
                          {policy_types &&
                            policy_types.map((policy) => (
                              <option
                                value={policy}
                                selected={policy === commission.policy_type}
                              >
                                {policy}
                              </option>
                            ))}{" "}
                        </select>
                      </td>
                      <td>
                        {new Date(
                          commission.date_created[0]
                        ).toLocaleDateString("en-US", { timeZone: "UTC" })}
                      </td>
                      <td>
                        <select className="max-width"  onChange = { (e) => handleAttributeUpdate(commission.policy_id, 'user_created_id', e.target.value)}>
                          {userIdAndFullName &&
                            Object.keys(userIdAndFullName).map((id) => (
                              <option
                                value={id}
                                selected={commission.user_created_id === id}
                              >
                                {userIdAndFullName[id]}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        <select className="max-width"  onChange = { (e) => handleAttributeUpdate(commission.policy_id, 'company', e.target.value)}>
                          {insurance_companies &&
                            insurance_companies.map((company) => (
                              <option
                                value={company}
                                selected={company === commission.company}
                              >
                                {company}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        {new Date(commission.effective_date).toLocaleDateString(
                          "en-US",
                          { timeZone: "UTC" }
                        )}
                      </td>
                      <td>
                        <select className="max-width"  onChange = { (e) => handleAttributeUpdate(commission.policy_id, 'sales_type', e.target.value)}>
                          <option value="New Business" selected = {commission.sales_type === 'New Business'}>New Business</option>
                          <option value="Rewrite" selected = {commission.sales_type === 'Rewrite'}>Rewrite</option>
                          <option value="Cancel Rewrite" selected = {commission.sales_type === 'Cancel Rewrite'}>Cancel Rewrite</option>
                          <option value="Cross Sale" selected = {commission.sales_type === 'Cross Sale'}>Cross Sale</option>
                          <option value="Cross Sale" selected = {commission.sales_type === null}>None</option>
                        </select>
                      </td>
                      <td>
                        <select className="max-width"  onChange = { (e) => handleAttributeUpdate(commission.policy_id, 'status', e.target.value)}>
                          <option
                            value="Active"
                            selected={
                              commission.status &&
                              commission.status[0] === "Active"
                            }
                          >
                            Active
                          </option>
                          <option
                            value="Cancelled"
                            selected={
                              commission.status &&
                              commission.status[0] === "Cancelled"
                            }
                          >
                            Cancelled
                          </option>
                          <option
                            value="Pending Cancel"
                            selected={
                              commission.status &&
                              commission.status[0] === "Pending Cancel"
                            }
                          >
                            Pending Cancel
                          </option>
                          <option
                            value="Terminated"
                            selected={
                              commission.status &&
                              commission.status[0] === "Terminated"
                            }
                          >
                            Terminated
                          </option>
                        </select>
                      </td>
                      <td>
                        {" "}
                        <div className="flex align-items-center light-dark-background">
                          <input
                            type="text"
                            placeholder={
                              commission.commissionable_premium
                                ? "$" + commission.commissionable_premium.toFixed(2)
                                : null
                            }
                            className="max-width box-border-2"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // Correctly access the key property
                                handleAttributeUpdate(commission.policy_id, 'commissionable_premium', e.target.value)
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        {" "}
                        {commission.company === "National General"
                          ? "$" + commission.number_of_drivers * 10
                          : "No Fee"}
                      </td>
                      <td>
                        {commission.company === "National General"
                          ? "$3 "
                          : "No Fee"}
                      </td>
                      <td>
                        {commission.organization_household_id !== null
                          ? "Commercial"
                          : "Personal"}
                      </td>
                      <td>{commission.address_line_2}</td>
                      <td>
                        {commission?.status[0] === "Active"
                          ? "Ready To Pay"
                          : "Do not pay"}
                      </td>
                      <td>{commission["multiline "] === true ? "1" : "0"}</td>
                      <td>{commission.monoline === true ? "1" : "0"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommissionPolicyPopup;
