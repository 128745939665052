import { configureStore} from '@reduxjs/toolkit'; 
import firstReducer from '../redux/firstSlice'
import tileDisplayIndex from './tileDisplayIndex';
import toggleSettings from './toggleSettings';
import pageNavigator from './pageNavigator'; 
import currentUserState from './currentUserState'
export const store = configureStore({ 
    reducer: {
        firstSlice: firstReducer, 
        tileDisplayIndex: tileDisplayIndex, 
        toggleSettings:toggleSettings,
        pageNavigator: pageNavigator, 
        currentUserState: currentUserState
     }, 
})