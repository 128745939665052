import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
const {handleUpdatingSharedLeadsStatus} = require('./fetches/fetch.js')


function LeadsTab({ sharedLeads, getSharedLeads }) {
  useEffect(() => {
    getSharedLeads(Cookies.get("currentHouseholdId"));
  }, []);

  const changeSharedLeadStatus = async(status, shared_leads_id) => { 
    const response = await handleUpdatingSharedLeadsStatus(status, shared_leads_id); 
    if(response.status === 500){ 
      alert('Error changing the shared lead status')
    }
    
  }
  return (
    <div className="max-width overflow-wrapper">
      {sharedLeads &&
        sharedLeads.map((lead) => (
          <div className="margin-bottom-10  border-bottom padding-bottom-5px " >
            <p>
              <h3>Notes:</h3> {lead.notes}
            </p>
            <p>
              <h3>Quote Type:</h3> {lead.quote_type}
            </p>
            <p className="">
              <h3>Status:</h3>
              <select className="background-color-full-dark  padding-left-none no-border  button-hover " onChange = { (e) => { changeSharedLeadStatus(e.target.value, lead.Id)}}>
                <option selected = {lead["status "] === null} value = 'Null'>Select Status</option>
                <option selected = {lead["status "] === 'Completed'} value = 'Completed'>Completed</option>
                <option selected = {lead["status "] === 'New'} value = 'New'>New</option>
                <option selected = {lead["status "] === 'In Progress'} value = 'In Progress'>In Progress</option>
              </select>
            </p>
          </div>
        ))}
    </div>
  );
}

export default LeadsTab;
