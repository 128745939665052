import React from "react";
import { policy_types } from "../jsonData/policy_types";
function SelectPolicyTypePopup({ setCurrentPopup, setPolicyType }) {
  const handleSelectChange = (e) => {
    switch (e.target.value) {
      case "Automobile":
        setCurrentPopup(3);
        break;
      case "Motorcycle":
        setCurrentPopup(2);
        break;
      default:
        setPolicyType(e.target.value);
        setCurrentPopup(11);
        break;
    }
  };
  return (
    <div
      className="background-color-full-dark border-radius-10px popup  "
      id="select-policy-type-popup"
    >
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setCurrentPopup(null)}
        >
          {" "}
          ❌
        </button>
      </div>
      <div className=" ">
        <div className="flex-container-el padding-20px ">
          <select
            className="background-color-full-dark padding-10px no-border mediumtext button-hover max-width"
            onChange={(e) => handleSelectChange(e)}
          >
            <option value="" selected>
              Select Policy Type
            </option>
            {policy_types &&
              policy_types.map((policy_type) => (
                <option value={policy_type}>{policy_type}</option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default SelectPolicyTypePopup;
