export const policy_types = [
    "Annuity",
    "Automobile",
    "Boat",
    "Boat Owners",
    "Builders Risk",
    "Business Owners Package",
    "Comprehensive Personal Liability",
    "Condo",
    "Condominium",
    "Contract",
    "Crime",
    "Disability",
    "Directors and Officers",
    "Earthquake",
    "Errors and Omissions",
    "Event",
    "Excess and Surplus",
    "Excess Liability",
    "Fixed Annuity",
    "Flood",
    "General Liability",
    "Glass",
    "Health",
    "Home",
    "Inland Marine",
    "Index Annuity",
    "Installation Builders Risk",
    "Jewelry",
    "Landlords",
    "Life",
    "Long-Term Care",
    "Mexican Travel",
    "Miscellaneous Bond",
    "Mobile Home",
    "Motorcycle",
    "NSF",
    "Off-Road Vehicle",
    "OTHER",
    "Package",
    "Pet Health",
    "Property",
    "Property (Dwelling Fire)",
    "PUP",
    "Recreational Vehicles",
    "Renters",
    "Roadside",
    "Schedule Personal Property",
    "Small Commercial Package",
    "Surety Bond",
    "Umbrella",
    "Variable Annuity",
    "Windowstorm",
    "Workers Comp"
  ];
  