import { createSlice } from '@reduxjs/toolkit'


const initialState = { page: "dashboard"}
const navigatePage = createSlice({ 
    name: 'navigatePage', 
    initialState, 
    reducers: { 
        changePageState(state, action) { 
            state.page = action.payload
        }, 
        resetState(state){ 
            state.page = initialState.page
        }
    }

})

export const { changePageState, resetState } = navigatePage.actions; 
export default navigatePage.reducer;