import { React, useRef } from "react";
import Cookies from "js-cookie";
import usePreventDoubleClick from "../usePreventDoubleClick";
function UnderwritingActionpopup({
  api,
  usersData,
  policies,
  setCurrentPopup,
  fetchUwActions,
  setSwitchNumber,
  setCurrentHouseholdId,
}) {
  const agent_id = useRef();
  const additional_documents = useRef();
  const endorsements = useRef();
  const proof_of_home_ownership = useRef();
  const drivers_license = useRef();
  const fl_dl_check = useRef();
  const policy_number = useRef();
  const notes = useRef();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const CREATE_UWA_OBJ = () => {
    if (policy_number.current.value === "N/A") {
      alert(
        policy_number.current.value + " " + "Is not a valid policy number!"
      );
      return;
    }
    const requestTypes = {
      additional_documents: additional_documents.current.checked,
      endorsements: endorsements.current.checked,
      proof_of_home_ownership: proof_of_home_ownership.current.checked,
      drivers_license: drivers_license.current.checked,
      fl_dl_check: fl_dl_check.current.checked,
    };

    const UWA_OBJ = {
      userId: Cookies.get("userid"),
      policy_id: policy_number.current.value,
      requestTypesInput: requestTypes,
      selectedAgent: agent_id.current.value,
      notes: notes.current.value,
    };
    return UWA_OBJ;
  };

  const POST_UNDERWRITING_ACTIONS = async (UWA_OBJ) => {
    const response = await fetch(`${api}/api/uw_action_request`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("jwt")}`,
      },
      body: JSON.stringify({
        household_id: Cookies.get("currentHouseholdId"),
        userId: UWA_OBJ.userId,
        policy_id: UWA_OBJ.policy_id,
        requestTypesInput: UWA_OBJ.requestTypesInput,
        selectedAgent: UWA_OBJ.selectedAgent,
        notes: UWA_OBJ.notes,
      }),
    });
    return response;
  };

  const CREATE_NEW_UWA = async () => {
    const UWA_OBJ = CREATE_UWA_OBJ();

    if (UWA_OBJ) {
      const response = await POST_UNDERWRITING_ACTIONS(UWA_OBJ);
      if (response.status === 500) {
        alert("Internal Server Error");
      } else {
        await fetchUwActions();
        setCurrentPopup(null);
      }
    }
  };

  return (
    <>
      <div
        className="background-color-full-dark border-radius-10px popup width-400px"
        id="add-uw-action"
      >
        <div className="black-background  text-align-right border-radius-top-10px ">
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setCurrentPopup(null)}
          >
            ❌
          </button>
        </div>
        <div className="flex-container-el padding-20px">
          <h3 className="text-align-left"> New UW Request</h3>
          <label
            for="firstname"
            className="background-color-full-dark text-align-left margin-top-10px"
          >
            Select Agent*
          </label>
          {usersData && (
            <select
              className="background-color-full-dark padding-10px no-border mediumtext button-hover max-width"
              ref={agent_id}
            >
              {usersData.map((userData) => (
                <option
                  id="assigned_to"
                  className="uw-input margin-top-10px"
                  key={userData.id}
                  value={userData.id}
                >
                  {userData.full_name}
                </option>
              ))}
            </select>
          )}
          <div className="flex-across-space-between max-width">
            <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px ">
              Additional Documents{" "}
            </label>
            <input
              type="checkbox"
              className="background-color-full-dark regularTextInput margin-top-5px   "
              ref={additional_documents}
              autocomplete="off"
            />
          </div>
          <div className="flex-across-space-between max-width">
            <label className="background-color-full-dark text-align-left margin-top-5px ">
              Endorsements
            </label>
            <input
              type="checkbox"
              className="background-color-full-dark regularTextInput margin-top-5px  "
              ref={endorsements}
              autocomplete="off"
            />
          </div>
          <div className="flex-across-space-between max-width">
            <label className="background-color-full-dark text-align-left margin-top-5px ">
              Proof Of Home Ownership
            </label>
            <input
              type="checkbox"
              className="background-color-full-dark regularTextInput margin-top-5px "
              ref={proof_of_home_ownership}
              autocomplete="off"
            />
          </div>
          <div className="flex-across-space-between max-width">
            <label className="background-color-full-dark text-align-left margin-top-5px ">
              Drivers License
            </label>
            <input
              type="checkbox"
              className="background-color-full-dark regularTextInput margin-top-5px  "
              ref={drivers_license}
              autocomplete="off"
            />
          </div>
          <div className="flex-across-space-between max-width">
            <label className="background-color-full-dark text-align-left margin-top-5px ">
              FL DL Check
            </label>
            <input
              type="checkbox"
              className="background-color-full-dark regularTextInput margin-top-5px  "
              ref={fl_dl_check}
              autocomplete="off"
            />
          </div>
          <label className="background-color-full-dark text-align-left margin-top-5px ">
            Notes*
          </label>
          <textarea
            className="background-color-full-dark padding-10px no-border mediumtext button-hover max-width"
            ref={notes}
          ></textarea>
          <label className="background-color-full-dark text-align-left margin-top-5px ">
            Select Policy Number*
          </label>
          <select
            className="background-color-full-dark padding-10px no-border mediumtext button-hover max-width "
            ref={policy_number}
          >
            <option value="N/A" selected>
              {" "}
              N/A
            </option>
            {policies &&
              policies.map((policy) => (
                <option value={policy.policy_id}>{policy.policy_id_two}</option>
              ))}
          </select>
          <button
            type="button"
            class="button-default green-submit-btn margin-top-10px max-width "
            id="add=driver-submit-btn"
            onClick={() => preventDoubleClick(CREATE_NEW_UWA)}
            disabled={isButtonDisabled}
          >
            Add UW Action
          </button>
        </div>
      </div>
    </>
  );
}

export default UnderwritingActionpopup;
