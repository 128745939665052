import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import PoliciesRelatedToUserPopup from "./policy_popups/PoliciesRelatedToUserPopup";
import HouseholdViewedToday from "./dashboard_popup/HouseholdViewedToday";


const {
  getDashboardStats,
} = require("./fetches/fetch");
function Dashboard({
  currentUserData,
  userIdAndFullName,
  setSwitchNumber,
  setCurrentHouseholdId,
}) {
  const [togglePoliciesPopup, setTogglePoliciesPopup] = useState(false);
  const [toggleHouseholdActivityView, setToggleHouseholdActivityView] =
    useState(false);
  const [viewUserID, setViewUserID] = useState(Cookies.get("userid"));
  const [dashboardData, setDashBoardData] = useState();

  const getDashBoardStatsAsync = async () => {
    const response = await getDashboardStats(viewUserID);
    const data = await response.json();
    setDashBoardData(data);
  };

  useEffect(() => {
    getDashBoardStatsAsync();
  }, [viewUserID]);

  return (
    <>
      {toggleHouseholdActivityView && (
        <HouseholdViewedToday
           viewUserID={viewUserID}
          userIdAndFullName={userIdAndFullName}
          setToggleHouseholdActivityView={setToggleHouseholdActivityView}
          setSwitchNumber={setSwitchNumber}
          setCurrentHouseholdId={setCurrentHouseholdId}
        />
      )}
      {togglePoliciesPopup && (
        <PoliciesRelatedToUserPopup
          setViewUserID={setViewUserID}
          currentUserData={currentUserData}
          setTogglePoliciesPopup={setTogglePoliciesPopup}
          userIdAndFullName={userIdAndFullName}
          setSwitchNumber={setSwitchNumber}
          setCurrentHouseholdId={setCurrentHouseholdId}
          viewUserID={viewUserID}
        />
      )}
      <div>
       
        <h3 className="padding-10px">Preformance</h3>
        <div className=" ">
          {currentUserData && currentUserData[0]?.role === "Admin" ? (
            <>
              <select
                className="background-color-full-dark padding-5px no-border mediumtext button-hover margin-left-5  "
                onClick={(e) => setViewUserID(e.target.value)}
              >
                <option value="">All Users</option>
                {userIdAndFullName &&
                  Object.keys(userIdAndFullName).map((userID) =>
                    userID !== Cookies.get("userid") ? (
                      <option value={userID}>
                        {userIdAndFullName[userID]}
                      </option>
                    ) : (
                      <option selected value={userID}>
                        {userIdAndFullName[userID]}
                      </option>
                    )
                  )}
              </select>

              <buton
                className="cursor-pointer padding-5px green-submit-btn margin-top-10px"
                onClick={() => getDashBoardStatsAsync()}
              >
                Refresh
              </buton>
            </>
          ) : null}
        </div>
        <div
          name="upper_stats"
          className="padding-10px grid-container-dashboard margin-top-10px"
        >
          <div className="stat">
            <h2
              className={`fade-in  cursor-pointer`}
              onClick={() => setTogglePoliciesPopup(true)}
            >
              $
              {dashboardData?.total_monthly_prem !== null && dashboardData
                ? dashboardData?.total_monthly_prem.toFixed(2)
                : 0}
            </h2>
            <h4>Monthly Premium Total</h4>
          </div>
          <div className="stat">
            <h2 className={`fade-in `}>
              {dashboardData ? dashboardData?.close_ratio?.toFixed(2) : 0}%
            </h2>
            <h4>Close Ratio</h4>
          </div>
          <div className="stat">
            <h2 className={`fade-in  ${dashboardData ? "" : "blurred"}`}>
              {dashboardData ? dashboardData.quotes_amount : 0}
            </h2>
            <h4>Quotes</h4>
          </div>
          <div className="stat">
            <h2 className={`fade-in  ${dashboardData ? "" : "blurred"}`}>
              {dashboardData ? dashboardData?.num_of_policies : 0}{" "}
            </h2>
            <h4>Active Policies</h4>
          </div>
          <div className="stat">
            <h2 className={`fade-in  ${dashboardData ? "" : "blurred"}`}>
              {dashboardData ? dashboardData?.uw_action_count : 0}
            </h2>
            <h4>UW Actions Required</h4>
          </div>
          <div className="stat">
            <h2 className={`fade-in  ${dashboardData ? "" : "blurred"}`}>
              {dashboardData && dashboardData.items > 0
                ? dashboardData?.items
                : 0}
            </h2>
            <h4>Items</h4>
          </div>
          <div className="stat">
            <h2 className="fade-in">0</h2>
            <h4>New Text SMS</h4>
          </div>
          <div className="stat">
            <h2 className="fade-in">0</h2>
            <h4>New Email</h4>
          </div>
          <div className="stat ">
            <h2 className="fade-in">{dashboardData && dashboardData.calls}</h2>
            <h4>Daily Call Volume</h4>
          </div>
            <div
              className="stat cursor-pointer "
              onClick={() => setToggleHouseholdActivityView(true)}
            >
              <h2 className="fade-in">
                {dashboardData?.dailyHouseholdViewed &&
                  dashboardData.dailyHouseholdViewed}
              </h2>
              <h4>Households Viewed Today</h4>
            </div>
        </div>
        <div name="upcoming_comissions ">
          <h3 className="margin-top-20px padding-10px">Communication</h3>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
