import React, { useRef, useState } from "react";
import Cookies from 'js-cookie'
const { moveToSharkTank, assign_multiple_owners } = require("../fetches/fetch");

function Change_ownership({
  setCurrentPopup,
  userIdAndFullName,
  checkedHouseholds,
  fetchData,
  currentPage,
  toDate,
  fromDate,
  searchTerm,
  
}) {

  const [usersIDSet, setUsersIDSet] = useState(new Set());

  const handleUserSelection = (e) => {
    setUsersIDSet((prevSet) => {
      const newSet = new Set(prevSet)
      const value = e.target.value; 
      if(e.target.checked){ 
        newSet.add(value)
      }else { 
        newSet.delete(value)
      }
      return newSet
    });

  };

  const handleStatusChange = async() => { 
    const checkedHouseholdsArray = [...checkedHouseholds]; 
    const checkedUsersArray = [...usersIDSet]
    const response = await  assign_multiple_owners(checkedUsersArray , checkedHouseholdsArray, Cookies.get('userid'))
    if(response.status === 200){ 
       await fetchData(currentPage, searchTerm, toDate, fromDate);
       return setCurrentPopup(null);
    }
    return alert('Error changing status')
  }

  const handleMoveToSharkTank = async() => { 
    const checkedHouseholdsArray = [...checkedHouseholds]; 
    const response = await moveToSharkTank(checkedHouseholdsArray)
    if(response.status === 200){ 
      await fetchData(currentPage, searchTerm, toDate, fromDate);
      return setCurrentPopup(null);
    }
    return alert('Error changing status')
  }



  return (
    <>
      <div className="background-color-full-dark border-radius-10px  popup z-index">
         
        <div className="black-background  text-align-right border-radius-top-10px ">
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setCurrentPopup(null)}
          >
            ❌
          </button>
        </div>
        <div className="flex-container-el padding-10px">
          <h3 className="padding-10px">Assign to user </h3>
          {userIdAndFullName &&
            Object.keys(userIdAndFullName).map((id) => (
              <div className=" flex flex-across-space-between max-width text-align-left align-items-center padding-5px ">
                <label>{userIdAndFullName[id]}</label>
                <input type="checkbox" value={id}  onChange= {(e) => handleUserSelection(e)}/>
              </div>
            ))}
      
          <button
            type="button"
            className="button-default green-submit-btn margin-top-20px max-width padding-10px"
            onClick={() => handleStatusChange()}
          >
            Change Owner
          </button>
          <button
            type="button"
            className="button-default green-submit-btn margin-top-20px max-width padding-10px"
            onClick={() => handleMoveToSharkTank()}
          >
            Move To Shark Tank
          </button>
        </div>
      </div>
    </>
  );
}

export default Change_ownership;
