import React from "react";
import usePreventDoubleClick from "../usePreventDoubleClick";
function AddUserPopup({ fetchDataNewUser, togglePopUpById, setAddUserPopup }) {
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();
  const postUserData = () => {
    let userDataObj = {};
    let inputFields = document.querySelectorAll(".user-input");

    inputFields.forEach((inputField) => {
      userDataObj[inputField.name] = inputField.value;
    });
    fetchDataNewUser(userDataObj);
    setAddUserPopup(false);
  };

  return (
    <div
      className="background-color-full-dark border-radius-10px popup"
      id="add-user-popup"
    >
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setAddUserPopup(false)}
        >
          ❌
        </button>
      </div>
      <div className="flex-container-el padding-20px">
        <h3 className="text-align-left margin-bottom-10">Add New User </h3>
        <label
          for="lastname"
          className="background-color-full-dark text-align-left   margin-top-5px "
        >
          Username
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input "
          name="username"
          autocomplete="off"
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px "
        >
          Password
        </label>
        <input
          type="password"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input  "
          name="password"
          autocomplete="off"
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px "
        >
          First Name
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input"
          name="firstname"
          autocomplete="off"
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px "
        >
          Last Name
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input"
          name="lastname"
          autocomplete="off"
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px "
        >
          Role
        </label>
        <select
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input"
          name="role"
          autocomplete="off"
        >
          <option value="Admin">Admin</option>
          <option value="Supervisor">Supervisor</option>
          <option value="Agent">Agent</option>
          <option value="Customer Service">Customer Service</option>
        </select>
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px "
        >
          Email
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width jewelery-coverage-input user-input"
          name="email"
          autocomplete="off"
        />
        <button
          type="button"
          class="button-default green-submit-btn margin-top-10px max-width "
          id="add=driver-submit-btn"
          onClick={() => preventDoubleClick(postUserData)}
          disabled={isButtonDisabled}
        >
          Add New User
        </button>
      </div>
    </div>
  );
}

export default AddUserPopup;
