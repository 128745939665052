import { useEffect, useRef, useLayoutEffect, useState } from "react";

import Cookies from "js-cookie";
import Draggleable from "react-draggable";
import "./chatBox.css";

let { api } = require("./apiConfig");

const user_id = Cookies.get("userid");

function ChatBox({
  setShowChatBox,
  chatBoxUserData,
  socket,
  chatMessages,
  setNotificationCount,
  chatDisplayName,
  fromUserID,
  setFromUserID,
  userIdAndFullName,
}) {
  useEffect(() => {
    const chatbox = document.getElementById("chatbox");

    chatbox.scrollTop = chatbox.scrollHeight;
    //chatMessages gets the history of the chat.
  }, [chatMessages]);

  useEffect(() => {
    const sentMessages = document.querySelectorAll(".created");
    sentMessages.forEach((message) => {
      message.remove();
    });
  }, [chatBoxUserData]);

  const updateSendMessage = (string) => {
    setNotificationCount(0);
    const fromUserId = user_id;
    const toUserId = chatBoxUserData;

    let input = string.value;
    const chatbox = document.getElementById("chatbox");
    const sendChatDiv = document.createElement("div");

    sendChatDiv.className = "send-chat created";
    sendChatDiv.innerHTML = `
    <p for="chat-bubble" data-testid="sender-message" class="margin-right-5px"> ${
      userIdAndFullName[user_id]
    } ${new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}</p>
    <div class='black background chat-bubble-send wrap-text'>
      ${input}
    </div>
    `;

    if (input) {
      const messageData = {
        fromUserId: fromUserId,
        toUserId: toUserId,
        message: input,
      };

      socket.emit("newMessage", { messageData });

      chatbox.appendChild(sendChatDiv);
      chatbox.scrollTop = chatbox.scrollHeight;
    }

    document.getElementById("chat_input").value = "";
  };

  const receiveMessageHandler = (arg, from_user_id) => {
    const chatbox = document.getElementById("chatbox");
    const receiveChatDiv = document.createElement("div");
    receiveChatDiv.className = "receive-chat created";
    receiveChatDiv.innerHTML = `
    <p for="chat-bubble" class="margin-right-5px">${
      userIdAndFullName[from_user_id]
    } ${new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}</p>
    <div class='black background chat-bubble-receive wrap-text'>
      ${arg}
    </div>
    `;
    if (chatbox) {
      chatbox.appendChild(receiveChatDiv);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      updateSendMessage(document.getElementById("chat_input"));
    }
  };

  useEffect(() => {
    socket.on(
      "receiveMessages",
      (messages, fromUserId, toUserId) => {
        setFromUserID(fromUserId);

        if (toUserId == Cookies.get("userid")) {
          receiveMessageHandler(messages, fromUserId);
        }
      },
      [chatDisplayName]
    );

    return () => {
      socket.off("receiveMessages");
    };
  }, [socket, Cookies.get("userid")]);

  return (
    <Draggleable cancel=".chat-bubble-receive, .regularTextInput, .chat-bubble-send">
      <div className="position-absolute width-chat opacity ">
        <div className="black-background text-align-right border-radius-top-10px flex-across-space-between">
          <p className="inline-el  black-background">{chatDisplayName} </p>
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setShowChatBox(false)}
          >
            ❌
          </button>
        </div>
        <div className="padding-20px">
          <div className="flex-column-no-ho-centered chat" id="chatbox">
            {chatMessages &&
              chatMessages.map((message) => {
                if (message.from_user_id === user_id) {
                  return (
                    <div className="send-chat" key={message.message_id}>
                      <p
                        for="chat-bubble"
                        data-testid="sender-message"
                        className="margin-right-5px"
                      >
                        {userIdAndFullName[user_id] + " \n"}
                        {" \n" +
                          new Date(message.date_created).toLocaleTimeString(
                            [],
                            {
                              weekday: "short",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                      </p>
                      <div className="black background chat-bubble-send wrap-text">
                        {message.text}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="receive-chat" key={message.message_id}>
                      <p
                        for="chat-bubble"
                        data-testid="sender-message"
                        className="margin-right-5px"
                      >
                        {userIdAndFullName[fromUserID] + " \n"}
                        {" \n" +
                          new Date(message.date_created).toLocaleTimeString(
                            [],
                            {
                              weekday: "short",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                      </p>
                      <div className="black background chat-bubble-receive wrap-text">
                        {message.text}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="control">
          <input
            type="text"
            className="regularTextInput input-width"
            data-testid="sender-message-input"
            id="chat_input"
            onKeyDown={handleKeyPress}
          />
          <button
            className="green-submit-btn inline-el"
            data-testid="sender-message-btn"
            onClick={() =>
              updateSendMessage(document.getElementById("chat_input"))
            }
          >
            Send Message
          </button>
        </div>
      </div>
    </Draggleable>
  );
}

export default ChatBox;
