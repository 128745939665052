import React, { useRef } from "react";
import Cookies from "js-cookie";
import usePreventDoubleClick from "../usePreventDoubleClick";
const { postTransaction } = require("../fetches/fetch");

function NewTransaction({
  setToggleNewTransaction,
  userIdAndFullName,
  currentPolicyId,
  fetchTransactions,
  fetchData,
}) {
  const transactionType = useRef();
  const premium = useRef();
  const producer_user_id = useRef();
  const doc_type = useRef();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const description = useRef();

  const createTransactionObj = () => {
    if (
      transactionType.current.value &&
      premium.current.value &&
      producer_user_id.current.value &&
      description.current.value
    ) {
      const transActionForm = new FormData();
      transActionForm.append("type", transactionType.current.value);
      transActionForm.append("premium", premium.current.value);
      transActionForm.append(
        "producer_user_id",
        producer_user_id.current.value
      );
      transActionForm.append("doc_type", doc_type.current.value);
      transActionForm.append(
        "doc_file",
        document.getElementById("doc_file").files[0]
      );
      transActionForm.append("description", description.current.value);
      transActionForm.append("policy_id", currentPolicyId);
      transActionForm.append("household_id", Cookies.get("currentHouseholdId"));
      transActionForm.append("transaction_type", transactionType.current.value);
      return transActionForm;
    }

    return alert("Missing required input field!");
  };

  const createTransaction = async () => {
    const formDataObj = createTransactionObj();
    if (typeof formDataObj === "object") {
      try {
        const response = await postTransaction(formDataObj);
        if (response.status === 500) {
          return alert(
            "Error creating tranasction network error or incorrect data type entered"
          );
        }
        fetchData();
        fetchTransactions(currentPolicyId);
        return setToggleNewTransaction(null);
      } catch (error) {}
    }
  };

  return (
    <div>
      <div
        className="background-color-full-dark border-radius-10px popup z-index "
        id="automobile-popup"
      >
        <div className="black-background  text-align-right border-radius-top-10px  ">
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setToggleNewTransaction(null)}
          >
            ❌
          </button>
        </div>
        <div>
          <div className="light-dark-background flex-container-el padding-20px ">
            <label className="text-align-left margin-top-5pxpx light-dark-background">
              Transaction Type*
            </label>
            <select
              className="background-color-full-dark regularTextInput margin-top-5px  max-width"
              ref={transactionType}
            >
              <option value="Endorsement">Endorsement</option>
              <option value="Payment">Payment</option>
              <option value="Reinstatement">Reinstatement</option>
              <option value="Cancellation Description">
                Cancellation Description
              </option>
            </select>
            <label className="text-align-left x margin-top-5pxpx light-dark-background">
              Premium*
            </label>
            <input
              type="text"
              className="background-color-full-dark regularTextInput margin-top-5px  max-width"
              ref={premium}
            />
            <label className="text-align-left margin-top-5pxpx margin-top-5pxpx light-dark-background">
              Producer*
            </label>
            <select
              className="background-color-full-dark regularTextInput margin-top-5px  max-width"
              ref={producer_user_id}
            >
              {Object.keys(userIdAndFullName).map((user_id) => (
                <option value={user_id}>{userIdAndFullName[user_id]}</option>
              ))}
            </select>
            <label className="text-align-left  margin-top-5pxpx light-dark-background">
              Document Type
            </label>
            <select
              className="background-color-full-dark regularTextInput margin-top-5px  max-width"
              ref={doc_type}
            >
              <option value="Additional Docs">Additional Docs</option>
              <option value="Endorsements">Endorsements</option>
              <option value="Proof Of Home Ownerhsip">
                Proof Of Home Ownership
              </option>
              <option value="Drivers License">Drivers License</option>
              <option value="FL DL Check">Fl Dl Check</option>
              <option value="New Business">New Business</option>
            </select>
            <label className="text-align-left margin-top-5pxpx light-dark-background">
              Document{" "}
            </label>
            <input
              type="file"
              className="light-dark-background "
              id="doc_file"
            />
            <label className="text-align-left margin-top-5pxpx margin-top-5pxpx light-dark-background">
              Description*{" "}
            </label>
            <input
              type="text"
              className="background-color-full-dark regularTextInput margin-top-5px  max-width"
              ref={description}
            />
            <button
              type="button"
              class="button-default green-submit-btn margin-top-10px max-width "
              onClick={() => preventDoubleClick(createTransaction)}
              disabled={isButtonDisabled}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTransaction;
