import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { changePageState } from "./redux/pageNavigator";
import { useDispatch } from "react-redux";

const userid = Cookies.get("userid");
function RightSide({
  
  api,
  setSwitchNumber,
  setCurrentHouseholdId,
  recentUwActions,
})
{

  const dispatch = useDispatch();
  return (
    <>
      <div className=" flex-column-no-ho-centered max-height side-width right">
        <div className="light-dark-container flex-center flex-center-column  ">
          <h3 className="light-dark-container inline-el flex-spacebetween ">
            Dialer Settings
          </h3>
          <select id="select-dialer" className="inline-block"></select>
          <input
            type="text"
            className="regularTextInput background-light-container max-width-100"
          />
          <div className="" id="dialpad">
            <button type="button" className="dialpad-btn">
              1
            </button>
            <button type="button" className="dialpad-btn">
              2
            </button>
            <button type="button" className="dialpad-btn">
              3
            </button>
            <button type="button" className="dialpad-btn">
              4
            </button>
            <button type="button" className="dialpad-btn">
              5
            </button>
            <button type="button" className="dialpad-btn">
              6
            </button>
            <button type="button" className="dialpad-btn">
              7
            </button>
            <button type="button" className="dialpad-btn">
              8
            </button>
            <button type="button" className="dialpad-btn">
              9
            </button>
            <button type="button" className="dialpad-btn dialpad-btn-0">
              0
            </button>
          </div>
        </div>

        <div className="light-dark-container margin-top-10px  max-height ">
          <h3 className="light-dark-container overflow">UW Action Required</h3>

          {recentUwActions &&
            recentUwActions.map((action, index) =>
              index < 8 ? (
                <div
                  key={index}
                  href="#"
                  className="light-dark-container block-display cursor-pointer hover"
                  onClick={() => {
                    setCurrentHouseholdId(action.household_id);
                    Cookies.set("currentHouseholdId", action.household_id);
                    dispatch(changePageState('lead'));
                  }}
                >
                  {action.firstname
                    ? action.firstname +
                      " " +
                      action.lastname +
                      " " +
                      new Date(action.due_date).toLocaleDateString("en-US")
                    : "UW ACTION" + " " + index + 1}
                </div>
              ) : null
            )}
        </div>

        <div className="light-dark-container margin-top-10px max-height  ">
          <h3 className="light-dark-container">Text Messages</h3>
          <label for="sms-content" className="light-dark-container">
            From:
          </label>
          <p id="sms-content" className="inline-el light-dark-container">
            {" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default RightSide;
