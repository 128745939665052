import {React, useState, useRef} from 'react'
import PlacesAutocomplete from '../PlacesAutoComplete';
import Cookies from 'js-cookie';
import usePreventDoubleClick from "../usePreventDoubleClick";


export default function NewHouseHolds({ setActiveComponentIndex ,setCurrentPopup, api, setLatestHousehold, parentHouseholdId, setParentHouseholdId}) {


    const [cityVal, setCityVal] = useState();
    const [stateVal, setStateVal] = useState();
    const [postCodeVal, setPostCodeVal] = useState();
    const home_phone = useRef();
    const adrs_line_two = useRef();
    const city = useRef();
    const state = useRef();
    const zip_code = useRef();
    const email = useRef();
    const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();


    const POST_NEW_HOUSEHOLD = async(householdData) => { 
      if(Cookies.get('jwt')){ 
      const response = await fetch(`${api}/api/household`, { 
        method : 'POST', 
        headers: { 
           'Content-Type': 'application/json',
           'Authorization': `${Cookies.get('jwt')}`
        }, 
        body: JSON.stringify({
          user_id: Cookies.get('userid'),
          home_phone : householdData.home_phone,
          address_line_one : householdData.address_line_one,
          address_line_two : householdData.address_line_two,
          city : householdData.city,
          state : householdData.state,
          zip_code : householdData.zip_code, 
          parentHouseholdId: parentHouseholdId ? parentHouseholdId : null,
          email: householdData.email ? householdData.email : null
      
        })
      })
      
      return response;
    }
     else { 
      window.location.href = '/';
    }

  }

    const CREATE_HOUSEHOLD_DATA_OBJ = () => { 
      const adrs_line_one = document.getElementById('adrs_line_one');
      let householdData = {}
      if(home_phone.current.value  && adrs_line_one.value && city.current.value && state.current.value && zip_code.current.value && email.current.value ){ 
        householdData = {
          home_phone : home_phone.current.value,
          address_line_one : adrs_line_one.value,
          address_line_two : adrs_line_two.current.value,
          city : city.current.value,
          state : state.current.value,
          zip_code : zip_code.current.value, 
          email: email.current.value
        }
    }
    else { 
      return alert("Missing an input field");
    }
    return householdData;

  }

  const CREATE_HOUSEHOLD_BTN = async() => { 
    
    

    const household_data = CREATE_HOUSEHOLD_DATA_OBJ();
    if(household_data){
      const response = await POST_NEW_HOUSEHOLD(household_data); 
      if(response.status === 500){ 
        
        alert('Make sure Zip Code And Phone Number are numbers!')
        setParentHouseholdId(null); //reset state so other newly created households dont have a parent household set already.
      }else { 
        setActiveComponentIndex(0)
        setLatestHousehold();
        setCurrentPopup(2);
        setParentHouseholdId(null); //reset state so other newly created households dont have a parent household set already.
      }
    }
    
  }
    const states = [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
  ];






  return (
   <>
    <div className='background-color-full-dark border-radius-10px  width-400px ' id = "add-household-crud-ops" >
            <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)}  >❌</button></div>
            <div className='flex-container-el padding-20px'> 
                <h3 className='text-align-left'> Add New Household</h3>
                <label className='background-color-full-dark text-align-left' >Home Phone</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref = {home_phone} />
                <label className='background-color-full-dark text-align-left margin-top-10px '>Adress Line 1</label>
                {/*<PlacesAutocomplete  setCityVal = {setCityVal} setStateVal={setStateVal} setPostCodeVal= {setPostCodeVal} id = "adrs_line_one" />*/}
                <input type = 'text' className = 'background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete = "off" id = "adrs_line_one" /> 
                <label  className='background-color-full-dark text-align-left margin-top-10px'>Address Line 2</label>
                <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref = {adrs_line_two}/>
                <label  className='background-color-full-dark text-align-left margin-top-10px'>City</label>
                <input type = 'text' className=' background-color-full-dark regularTextInput margin-top-5px max-width'  value = {cityVal} autocomplete="off" ref = {city}/>
                <label className='background-color-full-dark text-align-left margin-top-10px'>State</label>
                <select className=' background-color-full-dark regularTextInput margin-top-5px max-width' value = {stateVal} autocomplete="off" ref = {state}>
                  {states && states.map(state => ( 
                    <option value = {state}>{state}</option>
                  ))}
                </select>
                <label className='background-color-full-dark text-align-left margin-top-10px'>Zip Code</label>
                <input type = 'text' className=' background-color-full-dark regularTextInput margin-top-5px max-width'  autocomplete="off" ref = {zip_code}/>
                <label className='background-color-full-dark text-align-left margin-top-10px'>Email</label>
                <input type = 'text' className=' background-color-full-dark regularTextInput margin-top-5px max-width' autocomplete="off" ref = {email}/>
                <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width " onClick={() => preventDoubleClick(CREATE_HOUSEHOLD_BTN)} disabled = {isButtonDisabled}>Add New Household</button>
            </div>
       </div>
   </>
  )
}


