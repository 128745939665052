import React, { useState, useRef } from "react";
import Cookies from "js-cookie";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import AddNote from "../src/notes_popups/AddNote";
import Change_ownership from "./leads_page_popups/Change_ownership.js";
import ChangeSubStatus from "./leads_page_popups/ChangeSubStatus.js";
import MilitaryTechRoundedIcon from "@mui/icons-material/MilitaryTechRounded";
import { useSelector, useDispatch } from "react-redux";

import { changePageState } from "./redux/pageNavigator.js";
const api = require("./apiConfig.js");
function SearchResults({
  currentOwner,
  searchTerm,
  currentSelect,
  currentPage,
  driverData,
  setCurrentHouseholdId,
  userIdAndFullName,
  setCheckedHouseholds,
  checkedHouseholds,
  fetchData,
}) {
  const currentUser = useSelector((state) => state.currentUserState.user);
  const dispatch = useDispatch();
  const [changeSubSatus, setChangeSubStatus] = useState(false);
  const [addNotes, setAddNotes] = useState(false);
  const [selectedHouseholdID, setselectedHouseholdID] = useState();
  const [searchResults, setSearchResults] = useState(true);
  const [toggleOwnershipPopup, setToggleOwnershipPopup] = useState(false);
  
  const checkedHousehold = (e, household_id) => {
    const newCheckedHouseholds = new Set(checkedHouseholds);
    if (e.target.checked) {
      newCheckedHouseholds.add(household_id);
    } else {
      newCheckedHouseholds.delete(household_id);
    }
    setCheckedHouseholds(newCheckedHouseholds);
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    const formattedPhoneNumber = `(${cleanedPhoneNumber.substring(
      0,
      3
    )}) (${cleanedPhoneNumber.substring(3, 6)}) (${cleanedPhoneNumber.substring(
      6
    )})`;
    return formattedPhoneNumber;
  }

  const handleStatusClick = async () => {
    await fetchData(currentPage, searchTerm);
    return;
  };

  const handleLeadClick = async (household_id, user_id) => {
    const user_ids_array = JSON.parse(user_id);
 
    if(user_id === null && currentUser.role === "Agent"){ 
      return alert("Not assigned to this household!")
    }
  
      const isUserAssigned = user_ids_array ? user_ids_array.some((user) => Number(user.user_id) == Number(currentUser.id)) : null;
 
   
   
    if (
      !isUserAssigned  &&
      currentUser.role === "Agent"
    ) {
      return alert("Not assigned to this household!");
    }
    setCurrentHouseholdId(household_id);
    Cookies.set("currentHouseholdId", household_id);
    dispatch(changePageState('lead'));
  };



  return (
    <>
      <div className="search-overflow-wrapper margin-top-20px ">
        
        {addNotes && (
          <AddNote
            setCurrentPopup={setAddNotes}
            currentHouseholdId={selectedHouseholdID}
            searchResults={searchResults}
            api={api.api}
            fetchData={fetchData}
            currentPage={currentPage}
            searchTerm = {searchTerm}
          />
        )}
        {toggleOwnershipPopup && (
          <Change_ownership
            setCurrentPopup={setToggleOwnershipPopup}
            household_id={selectedHouseholdID}
            fetchData={fetchData}
            userIdAndFullName={userIdAndFullName}
            api={api.api}
            searchTerm={searchTerm}
            toDate={undefined}
            fromDate={undefined}
            currentPage={currentPage}
          />
        )}
        {changeSubSatus && ( 
          <ChangeSubStatus
            currentHouseholdID = {selectedHouseholdID}
            toggleDisplay = {setChangeSubStatus}
            fetchData={fetchData}
            currentPage={currentPage}
            searchTerm={searchTerm}
            toDate={undefined}
            fromDate={undefined}
            setChangeSubStatus = { setChangeSubStatus}
        
          
          />
        )}
        <table className="max-width" id="search-results">
          <thead className="z-index">
            <tr>
              <th className="width-5-percent">Select</th>
              <th>Customer</th>
              <th>
                {currentUser && (
                  <select
                    className="bg-color-black-1 max-width"
                    ref={currentSelect}
                    onChange={() => {
                      handleStatusClick();
                    }}
                  >
                    <option value="">Status</option>
                    <option value="New">New</option>
                    <option value="Quoted">Quoted</option>
                    <option value="Quoted Hot">Quoted Hot</option>
                    <option value="Called, No Contact">
                      Called, No Contact
                    </option>
                    <option value="Called, LVM">Called, LVM</option>
                    <option value="BAD NUMBER">BAD NUMBER</option>
                    <option value="Do Not Call">Do Not Call</option>
                    <option value="Not Elegible">Not Elegible</option>
                    <option value="Price is too High">Price is too High</option>
                    <option value="Policy in Force">Policy in Force</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Terminated">Terminated</option>
                    <option value="Contacted  Quoted & Call Back">
                      Contacted Quoted & Call Back
                    </option>
                    <option value="Not Interested">Not Interested</option>
                    <option value="Try again in 6 months">
                      Try again in 6 months
                    </option>
                    <option value="Followed Up">Followed Up</option>
                    <option value="Closed">Closed</option>
                    <option value="Already with Allstate">
                      Already With Allstate
                    </option>
                    <option value="Follow Up/Ready to Close.">
                      Follow Up/Ready to Close
                    </option>
                  </select>
                )}
              </th>
              <th>
                <select
                  className="bg-color-black-1 max-width"
                  onChange={() => {
                    handleStatusClick();
                  }} 
                  ref={currentOwner}
                >
                *If you are agent display only your ownership of leads in change owner options 
                  {currentUser &&
                  currentUser.role === "Agent" &&
                  userIdAndFullName ? (
                    <option default value={currentUser.id}>
                      {userIdAndFullName[currentUser.id]}
                    </option>
                  ) : (
                    userIdAndFullName &&
                    Object.keys(userIdAndFullName).map((user) => (
                      <option value={user}>{userIdAndFullName[user]}</option>
                    ))
                  )}
                 *If you are admin display all owners by default 
                  <option selected value="">
                    {" "}
                    All Owners
                  </option>
               
                </select>
              </th>
              <th className="z-index">Actions</th>
              <th>Latest Notes</th>
            </tr>
          </thead>

          <tbody id="search-results_table_body">
            {driverData && currentUser ? (
              driverData.map((driver, i) => (
              
                <tr key={i}>
                 
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedHouseholds.has(driver.household_id)}
                      onChange={(e) => checkedHousehold(e, driver.household_id)}
                    />
                  </td>
                  <td
                    onClick={() => {
                      handleLeadClick(driver.household_id, driver.user_ids);
                    }}
                  >
                    <label>
                      <b className="color-orange">Customer Name: </b>
                      {driver.firstname + " " + driver.lastname}
                    </label>
                    <br />
                    <label>
                      <b className="color-orange">Cellphone: </b>
                      {formatPhoneNumber(driver.home_phone)}
                    </label>
                    <br />
                    <label>
                      <b className="color-orange">Date Created: </b>
                      {new Date(driver.date_created).toLocaleDateString(
                        "en-US"
                      )}
                    </label>
                  </td>
                  <td
                    onClick={() => {
                      handleLeadClick(driver.household_id, driver.user_ids);
                    }}
                  >
                    {driver?.status}
                    <br />
                    {driver?.status_description}
                  </td>
                  <td
                    onClick={() => {
                      handleLeadClick(driver.household_id, driver.user_ids);
                    }}
                  > 
                      {driver.user_ids && JSON.parse(driver.user_ids).length !== 0 && driver.user_ids !== "[{}]"
                        ? JSON.parse(driver.user_ids).map(user_id => ( <div className= "width-fit-content flex-container-no-vert-center">{userIdAndFullName[user_id.user_id]}</div> ))
                        : "No Owner"}
                    
                  </td>
                  <td>
                    {(currentUser && driver.user_ids &&
                      currentUser.role === "Agent" &&
                      JSON.parse(driver.user_ids).some(user => Number(user.user_id) === Number(currentUser.id)))  ||
                      currentUser.role === "Admin" ||
                      currentUser.role === "Supervisor" ||
                      currentUser.role === "Supervisor" ||
                      currentUser.role === "Customer Service" ? (
                      <div
                        className=" flex space-between padding-10px border-radius-10px "
                        onClick={() =>
                          setselectedHouseholdID(driver.household_id)
                        }
                      >
                    
                        <div
                          className=" flex-container-el  fade-in color-green cursor-pointer "
                          onClick={() =>
                            (window.location.href = "tel:" + driver.home_phone)
                          }
                        >
                             
                          <button className=" border-none">
                            {<CallRoundedIcon />}
                          </button>
                          <label className="  ">Call</label>
                        </div>
                        <div
                          className=" flex-container-el  fade-in color-green cursor-pointer "
                          onClick={() => {
                            setAddNotes(true);
                          }}
                        >
                          <button className="  border-none">
                            {<EditNoteRoundedIcon />}
                          </button>
                          <label className="">Note</label>
                        </div>
                   
                        <div
                          className=" flex-container-el  fade-in color-green cursor-pointer"
                          onClick={() => setChangeSubStatus(true)}
                        >
                          <button className="  border-none">
                            {<MilitaryTechRoundedIcon />}
                          </button>
                          <label className="">Status</label>
                        </div>
                      </div>
                    ) : null}
                  </td>
                  <td
                    onClick={() => {
                      handleLeadClick(driver.household_id, driver.user_ids);
                    }}
                  >
                    {driver?.description}
                  </td>
                </tr>
              ))
            ) : (
              <div className="search-load">
                <img className="load-size" src="https://i.gifer.com/ZKZg.gif" />
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SearchResults;
