import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import NewLeads from './NewLeads.tsx'
import OldLeads from './oldLeads';
import FollowUps from './followUps';
import { useDispatch } from 'react-redux';
import { changePageState } from "./redux/pageNavigator";

const jwt = Cookies.get('jwt');
const user_id = Cookies.get('userid');

function ChartTime({ api, currentUserData,  setCurrentHouseholdId }) {
  const dispatch = useDispatch();
  const [leads, setLeads] = useState<Lead[] | null>(null);
  const [newLeads, setNewLeads] = useState<Lead[] | null>(null);
  const [i, setI] = useState(0);
  const [j, setJ] = useState(0);
  const toggleLeadsPopupRef  = useRef<HTMLInputElement>(null);

  interface Lead {
    address_line_one?: string;
    address_line_two?: string;
    city?: string;
    zip_code?: string;
    date_created: string;
    household_id: string[];
    home_phone?: string;
    
  }

  const dateOptions = { 
    weekday: 'long',
    year: 'numeric',
    month: 'long', 
    day: 'numeric'
    }

  useEffect(() => {
    //Fetch leads first than start the interval timer. 
    fetchUnassignedLeads(api)
    fetchUnassignedLeadsNew(api)
    
   const intervalId = setInterval(async () => { 
      await fetchUnassignedLeads(api);
      await fetchUnassignedLeadsNew(api);
    }, 5000);

    return () => clearInterval(intervalId); 
    
    
    
  }, [api]);


  const fetchUnassignedLeadsNew = async (api) => {
    const response = await fetch(`${api}/api/household/newLeads`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const data = await response.json();
    setNewLeads(data);

    if (toggleLeadsPopupRef.current) {
      toggleLeadsPopupRef.current.hidden = false;
    }

    if (data && data.length > 0) {
      setJ(Math.floor(Math.random() * data.length));
    }
  };

  

  const fetchUnassignedLeads = async (api) => {
    const response = await fetch(`${api}/api/household/unassigned`, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    const data = await response.json();
    setLeads(data);

    if (toggleLeadsPopupRef.current) {
      toggleLeadsPopupRef.current.hidden = false;
    }

  
  };

  const acceptLead = async(household_id_obj) => { 
    const household_id = household_id_obj;

    const response = await fetch(`${api}/api/household/assign?user_id=${user_id}&household_id=${household_id}&pipeline_assigned_lead=${true}`, { 
        method : "POST"
    })

    if(response.status === 500){ 
        alert("Lead was already claimed!");
        await fetchUnassignedLeads(api);
        await fetchUnassignedLeadsNew(api);
        return;
    } 
    Cookies.set('currentHouseholdId', household_id);
    setCurrentHouseholdId(household_id);
    dispatch(changePageState('lead'));

    
    
  }
  
  return (
   <>{leads ?(
      <div className='display-flex z-index-2   '>
        <div className = "flex-across-space-evenly  height-250px max-width-500  "> 
            <div className='grid-container'>
              {leads && leads.slice(0,3).map( (lead, index) => ( 
                    <OldLeads 
                    index ={index}
                    lead ={lead} 
                    i = {i}  
                    acceptLead = {acceptLead}
                    dateOptions={dateOptions}
                    />
              ))}
            </div>
            <div className=' grid-container '> 
              {newLeads && newLeads.map((newLead, index) =>( 
                    <NewLeads 
                    index = {index}
                    newLead= {newLead}
                    j = { j} 
                    acceptLead = {acceptLead}
                    dateOptions={dateOptions}
                    newLeads = {newLeads}
                    />
              ))}
          </div>
                 
        </div>
       </div>): <div className = "flex-center-column light-dark-container"><img   className = "light-dark-container ten-percent-width" src = "https://i.gifer.com/ZKZg.gif"/></div>}
       <div className='padding-right-10px  '>
        <FollowUps 
          currentUserData = {currentUserData} 
          acceptLead = {acceptLead}
         
          setCurrentHouseholdId = {setCurrentHouseholdId}
        />
      </div>
    </>
  );
}

export default ChartTime;
