import { createSlice } from '@reduxjs/toolkit'

 const currentUserState = createSlice({ 
    name: 'currentUserState', 
    initialState: { 
        user:null
    }, 
    reducers: { 
        setUser:(state, action) => { 
          
            state.user = action.payload;
        }
    }
})

export const {setUser} = currentUserState.actions; 
export default currentUserState.reducer