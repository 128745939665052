import React, { useEffect, useState, useRef } from "react";
import { policy_types } from "../jsonData/policy_types.js";
import Cookies from "js-cookie";
const {
  getHouseholdOwners,
  assign_multiple_owners,
  handleSharingLeads,
} = require("../fetches/fetch.js");
function LeadsTabPopup({
  setCurrentPopup,
  userIdAndFullName,
  getHouseholdData,
  getSharedLeads,
}) {
  const notes = useRef();
  const quote_type = useRef();

  useEffect(() => {
    handleGetOwners(Cookies.get("currentHouseholdId"));
  }, []);
  const getUserIds = () => {
    return Object.keys(checkBoxState).filter(
      (key) => checkBoxState[key] === true
    );
  };

  const handleAssigningOwners = async (household_ids) => {
    const userIDs = getUserIds();
    /*assign_multiple_owners takes household_ids as an array */
    await assign_multiple_owners(userIDs, [household_ids], Cookies.get('userid'));
    await getHouseholdData();
    const response = await handleSharingLeads(
      notes.current.value,
      quote_type.current.value,
      Cookies.get("currentHouseholdId")
    );
    if (response.status === 200) {
      await getSharedLeads(Cookies.get("currentHouseholdId"));
      return setCurrentPopup(null);
    }
  };
  const [owners, setOwners] = useState();
  const [checkBoxState, setCheckBoxState] = useState({});

  const handleGetOwners = async (household_id) => {
    const initialCheckBoxState = {};
    const response = await getHouseholdOwners(household_id);
    const data = await response.json();
    setOwners(data);
    console.log(owners);

    data.forEach((owner) => {
      initialCheckBoxState[owner.assigned_to_user_id] = true;
    });
    setCheckBoxState(initialCheckBoxState);
  };

  const handleCheckBoxChecked = (user_id) => {
    setCheckBoxState((prevState) => ({
      ...prevState,
      [user_id]: !prevState[user_id],
    }));
  };
  return (
    <div
      className="background-color-full-dark border-radius-10px popup width-400px"
      id="add-notes"
    >
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setCurrentPopup(null)}
        >
          ❌
        </button>
      </div>
      <div className="padding-20px flex-column-no-ho-centered">
        <h3 className="font-size-20px">Share this lead</h3>
        <label className="margin-top-5"><b>Current Owners:</b></label>
        {owners &&
          owners.map((owner) => (
            <p>- {userIdAndFullName[owner.assigned_to_user_id]}</p>
          ))}
        <label className="margin-top-10px"><b>Quote type:*</b></label>
        <select
          ref={quote_type}
          className="background-color-full-dark padding-10px no-border  button-hover max-width padding-left-none"
        >
          {policy_types &&
            policy_types.map((policy_type) => (
              <option value={policy_type}> {policy_type}</option>
            ))}
        </select>
        <label className="margin-top-5"><b>Assign to:*</b></label>
        {userIdAndFullName &&
          Object.keys(userIdAndFullName).map((user_id, index) =>
            owners &&
            owners.some(
              (owner) => owner.assigned_to_user_id === user_id
            ) ? null : (
              <div className="flex-across-space-between align-items-center  ">
                <p>{userIdAndFullName[user_id]}</p>
                <input
                  type="checkbox"
                  value={user_id}
                  checked={checkBoxState[user_id] || false}
                  onChange={() => handleCheckBoxChecked(user_id)}
                />
              </div>
            )
          )}
        <label className="margin-top-10px"><b>Notes:</b></label>
        <textarea className="height-100px max-width" ref={notes}></textarea>
        <button
          type="button"
          className="margin-top-10px max-width green-submit-btn margin-top-10px "
          onClick={() =>
            handleAssigningOwners(Cookies.get("currentHouseholdId"))
          }
        >
          Share Lead
        </button>
      </div>
    </div>
  );
}

export default LeadsTabPopup;
