import React, { useState, useEffect } from "react";
import DateRangePicker from "../src/DatePicker";
import Cookies from "js-cookie";
import Payroll from "./Payroll";
import CommissionPolicyPopup from "./policy_popups/CommissionPolicyPopup";
import { useDefaultReduceAnimations } from "@mui/x-date-pickers/internals";
import TimeTablePopup from "./time_table_popup/TimeTablePopup";
import EditTimeTablePopup from "./time_table_popup/EditTimeTablePopup";
const {
  get_time_punch,
  getUserCommissions,
  handleGettingDate,
  time_punch_by_date,
} = require("../src/fetches/fetch");

export function TimeTable({
  timePunches,
  currentUserData,
  setTotalHours,
  setEstimatedPay,
  user,
  returnTimePunches,
}) {
  const [dayPunchLog, setDayPunchLog] = useState();
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleEditTimePopup, setToggleEditTimePopup] = useState(false);
  const [editTimePunch, setToggleTimePunch] = useState();
  const [timeLoggedIn, setTimeLoggedIn] = useState();
  const [totalHoursWorkedForSelectedDate, setTotalHoursWorkedForSelectedDate] =
    useState();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleTimeTableRowClick = async (date, user_id, calculate_logged_hours) => {
    const response = await handleGettingDate(date, user_id);
    if (response.status === 200) {
      const data = await response.json();
      if(calculate_logged_hours === true){ 
        return setDayPunchLog(data);
      }else { 

      
      setTogglePopup(true);
      return setDayPunchLog(data);
      }
    }
    return alert("Erroring geting pucnhes for that day");
  };

  useEffect(() => {
    if (currentUserData && timePunches) {
      let hoursSum = 0;
      let paySum = 0;

      timePunches.forEach((timePunch) => {
        const firstPunch = new Date(timePunch.first_punch);
        const lastPunch = new Date(timePunch.last_punch);

        const hoursWorked = lastPunch.getHours() - firstPunch.getHours();
        const minutesWorked = lastPunch.getMinutes() - firstPunch.getMinutes();

        let adjustedHours = hoursWorked;
        let adjustedMinutes = minutesWorked;

        if (minutesWorked < 0) {
          adjustedHours -= 1; // Subtract one hour
          adjustedMinutes += 60; // Convert negative minutes to positive
        }

        // Add fractional hours
        adjustedHours += adjustedMinutes / 60;

        hoursSum += adjustedHours;
        paySum += adjustedHours * currentUserData[0].hourly_rate;
      });

      setTotalHours(hoursSum);
      setEstimatedPay(paySum);
    }
  }, [currentUserData, timePunches]);



  
  const calculateTimeLoggedIn = (punches) => {
    punches.sort((a, b) => new Date(a.time_punch) - new Date(b.time_punch));

    let totalLoggedOutTime = 0;

    for (let i = 1; i < punches.length; i++) {
      if (punches[i].action === "logout" && punches[i - 1].action === "login") {
        // Calculate the time difference between logout and login
        const loginTime = new Date(punches[i - 1].time_punch);
        const logoutTime = new Date(punches[i].time_punch);
        const differenceInMilliseconds = logoutTime - loginTime;

        // Add the time difference in minutes
        totalLoggedOutTime += differenceInMilliseconds / 60000; // converting milliseconds to minutes
      }
    }

    // Return total logged out time in hours and minutes
    const hours = Math.floor(totalLoggedOutTime / 60);
    const minutes = totalLoggedOutTime % 60;

    return { hours, minutes };
  };

  return (
    <div className="overflow-50-percent">
      <table className="max-width">
        <thead>
          <th>Date</th>
          <th>First Clock In</th>
          <th>Final Clock Out</th>
          <th>Hours</th>
          <th>Pay</th>
        </thead>
        <tbody id="search-results_table_body">
          {currentUserData &&
            timePunches &&
            timePunches.map((timePunch) => (
              <>
                <tr
                  onClick={() => {
                    handleTimeTableRowClick(timePunch.first_punch, user);
                    setTotalHoursWorkedForSelectedDate({ hours : timePunch.hours, minutes : timePunch.minutes });
                  }}
                >
                  <td>
                    {" "}
                    {daysOfWeek[new Date(timePunch.first_punch).getDay()]}
                    <br />
                    {new Date(timePunch.first_punch).toLocaleDateString()}
                  </td>
                  <td>
                    {new Date(timePunch.first_punch).toLocaleTimeString()}
                  </td>
                  <td>{new Date(timePunch.last_punch).toLocaleTimeString()}</td>
                  <td>{timePunch.hours + "h " + timePunch.minutes + "m "}</td>
                  <td>
                    {(() => {
                      const firstPunch = new Date(timePunch.first_punch);
                      const lastPunch = new Date(timePunch.last_punch);

                      const hoursWorked =
                        lastPunch.getHours() - firstPunch.getHours();
                      const minutesWorked =
                        lastPunch.getMinutes() - firstPunch.getMinutes();

                      // Adjust if minutesWorked is negative
                      const totalHours = hoursWorked + minutesWorked / 60;

                      // Multiply the total hours by the hourly rate
                      const totalPay =
                        currentUserData[0].hourly_rate * totalHours;

                      return `$${totalPay.toFixed(2)}`; // Show the result as a formatted currency
                    })()}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
      {togglePopup && (
        <TimeTablePopup
          setToggleTimePunch={setToggleTimePunch}
          returnTimePunches={returnTimePunches}
          setToggleEditTimePopup={setToggleEditTimePopup}
          handleTimeTableRowClick={handleTimeTableRowClick}
          user={user}
          currentUserData={currentUserData}
          timePunches={dayPunchLog}
          daysOfWeek={daysOfWeek}
          setTogglePopup={setTogglePopup}
          totalHoursWorkedForSelectedDate={totalHoursWorkedForSelectedDate}
        />
      )}
      {toggleEditTimePopup && (
        <EditTimeTablePopup
          handleTimeTableRowClick={handleTimeTableRowClick}
          user={user}
          editTimePunch={editTimePunch}
          returnTimePunches={returnTimePunches}
          daysOfWeek={daysOfWeek}
          timePunches={dayPunchLog}
          setTogglePopup={setToggleEditTimePopup}
        />
      )}
    </div>
  );
}

export function ManagementNavigation({
  currentUserData,
  currentManagementPage,
  setCurrentManagementPage,
}) {
  return (
    <>
      <div className="light-dark-background flex margin-bottom-10">
        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 0 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(0)}
        >
          Timsheet
        </div>
        {currentUserData && currentUserData[0].role === "Admin" ? (
          <div
            className={`light-dark-background padding-10px cursor-pointer ${
              currentManagementPage === 1 ? "management-nav" : null
            } `}
            onClick={() => setCurrentManagementPage(1)}
          >
            Payroll
          </div>
        ) : null}
        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 2 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(2)}
        >
          Commissions
        </div>
        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 3 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(3)}
        >
          Bonuses
        </div>
      </div>
    </>
  );
}

export function ManagementPage({
  currentUserData,
  userIdAndFullName,
  allUserData,
  fetchUserData,
}) {
  const [currentManagementPage, setCurrentManagementPage] = useState();
  const [timePunches, setTimePunches] = useState();
  const [user, setUser] = useState(Cookies.get("userid"));
  const [startDate, setStartDate] = useState(
    getFirstDateOfWeek().toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [orderedTimePunches, setOrderedTimePunches] = useState(0);
  const [estimatedPay, setEstimatedPay] = useState(0);
  const [totalHours, setTotalHours] = useState();
  useEffect(() => {
    returnTimePunches();
  }, [startDate, endDate, user]);

  function getFirstDateOfWeek() {
    const now = new Date(); // Current date
    const dayOfWeek = now.getDay(); // Day of the week (0 for Sunday, 1 for Monday, etc.)
    const diff = now.getDate() - dayOfWeek; // Subtract the day of the week to get the previous Sunday
    return new Date(now.setDate(diff)); // Return the date object for the start of the week
  }

  const returnTimePunches = async () => {
    await fetchUserData();
    const startOfCurrentWeek = getFirstDateOfWeek().toISOString();
    const response = await get_time_punch(startDate, endDate, user);
    const time_punches = await response.json();
    setTimePunches(time_punches);
  };

  return (
    <div>
      <div className=" flex-across align-items-center space-between margin-bottom-10">
        <h3 className="margin-left-10 ">
          {currentUserData && currentUserData[0].role === "Admin" ? (
            <select
              onChange={(e) => {
                setUser(e.target.value);
              }}
            >
              {Object.keys(userIdAndFullName).map((key) => (
                <option value={key}>{userIdAndFullName[key]}</option>
              ))}
            </select>
          ) : (
            currentUserData[0].role
          )}
          's Timesheet
        </h3>

        <DateRangePicker
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getFirstDateOfWeek={getFirstDateOfWeek}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="grid-management margin-bottom-10">
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Total Hours
            <h2 className="light-dark-background ">
              {totalHours ? totalHours && totalHours.toFixed(2) : "N/A"}
            </h2>
          </span>
        </div>
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Estimated Pay
            <h2 className="light-dark-background ">
              ${estimatedPay ? estimatedPay.toFixed(2) : "N/A"}
            </h2>
          </span>
        </div>
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Hourly Pay
            <h2 className="light-dark-background ">
              $
              {allUserData
                ? allUserData.filter((obj) => obj.id === user)[0].hourly_rate
                : "N/A"}
            </h2>
          </span>
        </div>
      </div>
      <TimeTable
        setTotalHours={setTotalHours}
        setEstimatedPay={setEstimatedPay}
        timePunches={timePunches}
        currentUserData={currentUserData}
        user={user}
        returnTimePunches={returnTimePunches}
      />
    </div>
  );
}

/*Loop through the time punches and figure out the day of the current being looped over and group all timestamps by day*/

export function Commissions({ currentUserData, userIdAndFullName }) {
  const [toggleCommissionPopup, setToggleCommissionPopup] = useState(false);
  const [currentUserID, setCurrentUserID] = useState();
  const [fromDate, setFromDate] = useState(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(startOfMonth.getMonth() - 1);
    startOfMonth.setDate(1); // Set to the first day of the month
    startOfMonth.setHours(0, 0, 0, 0);
    return startOfMonth.toISOString().split("T")[0];
  });
  const [toDate, setToDate] = useState(() => {
    const endOfMonth = new Date();
    endOfMonth.setMonth(endOfMonth.getMonth()); // Move to the next month
    endOfMonth.setDate(0); // Set to the last day of the previous month
    endOfMonth.setHours(23, 59, 59, 999); // Set time to the end of the day

    return endOfMonth.toISOString().split("T")[0];
  });
  const [userCommissions, setUserCommmissions] = useState();

  const setUserCommissionsData = async (fromDate, toDate) => {
    const response = await getUserCommissions(fromDate, toDate);
    const data = await response.json();
    setUserCommmissions(data);
  };

  useEffect(() => {
    setUserCommissionsData(fromDate, toDate);
  }, [toDate, fromDate]);

  return (
    <>
      {toggleCommissionPopup && (
        <CommissionPolicyPopup
          setToggleCommissionPopup={setToggleCommissionPopup}
          userIdAndFullName={userIdAndFullName}
          setCurrentUserID={setCurrentUserID}
          currentUserID={currentUserID}
        />
      )}
      <div>
        <span>From: {" " + " "}</span>
        <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <span>To:{" " + " "}</span>
        <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Total Premium Amount</th>
            <th>Commmission Amount</th>
          </tr>
        </thead>
        <tbody id="search-results_table_body">
          {userCommissions &&
            userCommissions.map((user) =>
              currentUserData[0].role === "Admin" ? (
                <tr
                  onClick={() => {
                    setCurrentUserID(user.id);
                    setToggleCommissionPopup(true);
                  }}
                >
                  <td>{user.full_name}</td>
                  <td>${user.total_prem.toFixed(2)}</td>
                  <td>${(user.total_prem * 0.04).toFixed(2)}</td>
                </tr>
              ) : (
                user.id === currentUserData[0].id &&
                currentUserData[0].role === "Agent" && (
                  <tr>
                    <td>{user.full_name}</td>
                    <td>${user.total_prem.toFixed(2)}</td>
                    <td>${(user.total_prem * 0.04).toFixed(2)}</td>
                  </tr>
                )
              )
            )}
        </tbody>
      </table>
    </>
  );
}

export function Bonus({}) {
  return (
    <>
      <div>Bonus container</div>
    </>
  );
}

export default function ManagementContainer({
  userIdAndFullName,
  currentUserData,
  allUserData,
  fetchUserData,
}) {
  const [currentManagementPage, setCurrentManagementPage] = useState(0);
  const pages = [
    <ManagementPage
      userIdAndFullName={userIdAndFullName}
      currentUserData={currentUserData}
      allUserData={allUserData}
      fetchUserData={fetchUserData}
    />,
    <Payroll userIdAndFullName={userIdAndFullName} allUserData={allUserData} />,
    <Commissions
      setCurrentManagementPage={setCurrentManagementPage}
      userIdAndFullName={userIdAndFullName}
      currentUserData={currentUserData}
    />,
    <Bonus />,
  ];
  return (
    <div>
      <ManagementNavigation
        setCurrentManagementPage={setCurrentManagementPage}
        currentManagementPage={currentManagementPage}
        currentUserData={currentUserData}
      />
      {pages[currentManagementPage]}
    </div>
  );
}
