import React from "react";
import { changePageState } from "./redux/pageNavigator.js";
import { useSelector, useDispatch } from "react-redux";
import Cookies from 'js-cookie';
const {handleDeletingNotifications} = require('../src/fetches/fetch.js');
function NotificationBar({ notificationPanel, userNotifications, setCurrentHouseholdId, fetchNewNotifications }) {
  const now = new Date();
  const formattedDate = now.toLocaleString("en-US", {
    weekday: "long",
    hour: "2-digit",
    minute: "2-digit",
  });
  const dispatch = useDispatch();
  const handleViewLead =(household_ids) => { 
    setCurrentHouseholdId(household_ids); 
    Cookies.set('currentHouseholdId', household_ids)
    dispatch(changePageState('lead'))
  } 
  const handleDelete = async(notification_id) => { 
    const reponse = await handleDeletingNotifications(notification_id); 
    if(reponse.status === 200){ 
      return fetchNewNotifications();
    }
    return alert('Error deleting notification');
  }
  return (
    <div
      className={`bg-color-black-1 max-height notification-bar  ${
        notificationPanel ? "active" : ""
      }`}
    >
      <div className="padding-10px bg-color-black-1 width-170px  ">
        <h3 className="bg-color-black-1  border-bottom margrin-bottom-5px padding-bottom-5px">
          {" "}
          My Notifications{" "}
        </h3>
        {userNotifications && userNotifications.length > 0 ? (
          userNotifications.map((notification) => (
            <div className="cursor-pointer  " >
              <button className="fade-in border-none padding-left-5px" onClick = {() => handleDelete(notification.notification_id)}>x</button>
              <li className="bg-color-black-1 margrin-bottom-5px padding-bottom-5px fade-in " onClick = {()=> handleViewLead(notification.household_id) }>
                {" "}
                {notification.notification_desc}
                <br />{" "}
                <span className="font-small bg-color-black-1">
                  <i className="bg-color-black-1">
                    {notification.date_created
                      ? new Date(notification.date_created).toLocaleString(
                          "en-US",
                          {
                            weekday: "long",
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      : null}
                  </i>
                </span>
              </li>
              
            </div>
          ))
        ) : (
          <li className="bg-color-black-1 margrin-bottom-5px padding-bottom-5px ">
            You are all caught up!
          </li>
        )}
      </div>
    </div>
  );
}

export default NotificationBar;
