import { React, useRef, useState } from "react";
import Cookies from "js-cookie";
import usePreventDoubleClick from "../usePreventDoubleClick";

function AddMotorcyclePolicyPopup({
  driverData,
  setCurrentPopup,
  insurance_companies,
  POST_POLICY,
  fetchData,
  vehicleData,
}) {
  const [items, setItems] = useState(0);
  const [numOfDrivers, setNumOfDrivers] = useState(0)
  const POLICY_NUMBER = useRef();
  const POLICY_STATUS = useRef();
  const POLICY_ADDRS = useRef();
  const POLICY_COMPANY = useRef();
  const EFFECTIVE_DATE = useRef();
  const BI_LIMITS = useRef();
  const PD_LIMTIS = useRef();
  const PIP_LIMITS = useRef();
  const GUEST_LIABILITY = useRef();
  const PIP_DEDUCTIBLE = useRef();
  const TOTAL_PREM = useRef();
  const primary_driver = useRef();
  const comm_prem = useRef();
  const PREM_LENGTH = useRef();
  const EXP_DATE = useRef();
  const sales_type = useRef();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();
  const handleCheck = async (event) => {
    if (event.target.checked) {
      setItems(items + 1);
    } else {
      setItems(items - 1);
    }
  };

  const CREATE_MOTOR_POLICY_OBJ = () => {
    if (TOTAL_PREM.current.value.includes("$")) {
      TOTAL_PREM.current.value = TOTAL_PREM.current.value.replace(/\$/g, "");
    }
    let monthly_prem = TOTAL_PREM.current.value / 12;
    if (
      POLICY_NUMBER.current.value &&
      POLICY_STATUS.current.value &&
      POLICY_ADDRS.current.value &&
      POLICY_COMPANY.current.value &&
      EFFECTIVE_DATE.current.value &&
      sales_type.current.value && 
      numOfDrivers !== 0 && 
      items > 0 &&
      EXP_DATE.current.value
    ) {
      let POLICY_OBJ = {
        user_id: Cookies.get("userid"),
        policy_type: "Motorcycle",
        household_id: Cookies.get("currentHouseholdId"),
        policy_number: POLICY_NUMBER.current.value,
        status: POLICY_STATUS.current.value,
        address_line_2: POLICY_ADDRS.current.value,
        effective_date: EFFECTIVE_DATE.current.value,
        bi_limits: BI_LIMITS.current.value,
        pd_limits: PD_LIMTIS.current.value,
        pip_limits: PD_LIMTIS.current.value,
        guest_liability: GUEST_LIABILITY.current.value,
        pip_deductible: PIP_DEDUCTIBLE.current.value,
        company: POLICY_COMPANY.current.value,
        monthly_prem: monthly_prem,
        items: items,
        numberOfDrivers: numOfDrivers, 
        sales_type: sales_type.current.value,
        total_prem: TOTAL_PREM.current.value,
        expiration_date: EXP_DATE.current.value,
        primary_driver: primary_driver.current.value,
        comm_prem: comm_prem.current.value,
      };
      return POLICY_OBJ;
    } else {
      alert("Missing a required field!");
      return;
    }
  };

  const CREATE_NEW_MOTOR_POLICY_BTN = async () => {
    const POLICY_OBJ = CREATE_MOTOR_POLICY_OBJ();
    if (POLICY_OBJ) {
      const response = await POST_POLICY(POLICY_OBJ);
      if (response.status === 500) {
        alert("Policy Number invalid or already exists!");
      } else {
        fetchData();
        setCurrentPopup(null);
      }
    }
  };
  const handleDriverCheck = (event) => { 
    if (event.target.checked){ 
      setNumOfDrivers((driver) => driver + 1)
    }else { 
      setNumOfDrivers((driver) => driver -1);
    }

  }


  return (
    <div
      className="background-color-full-dark border-radius-10px popup"
      id="motorcycle-popup"
    >
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setCurrentPopup(null)}
        >
          ❌
        </button>
      </div>
      <div className=" padding-20px">
        <h3 className="text-align-left margin-bottom-10">
          Add Motorcycle Policy
        </h3>
        <div className=" padding-20px height-half flex-column-no-ho-centered ">
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5px  margin-top-5pxpx  "
          >
            Select Vehicles*
          </label>
          {vehicleData &&
            vehicleData.map((vehicle) => (
              <>
                <div className="flex-across-space-between max-width">
                  <p>
                    {vehicle.year + " " + vehicle.make + " " + vehicle.model}
                  </p>
                  <input
                    type="checkbox"
                    name="select_vehicle"
                    onChange={handleCheck}
                  />
                </div>
              </>
            ))}
              <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5px  margin-top-5pxpx  "
          >
            Select Drivers*
          </label>

          {driverData && driverData.map(driver => ( 
            <div className="flex-across-space-between max-width">
            <p>{driver.firstname + " " + driver.lastname}</p>
            <input
              type="checkbox"
              name="select_vehicle"
              onChange={ (e) => handleDriverCheck(e) }
             />
            </div>
          ))}
          <label className="background-color-full-dark text-align-left margin-top-10px margin-top-5px">
            Policy Number*
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px max-width "
            id="policy_number"
            autocomplete="off"
            ref={POLICY_NUMBER}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px ">
            Policy Status*
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px  max-width   "
            id="status"
            autocomplete="off"
            ref={POLICY_STATUS}
          >
            <option value="Active">Active</option>
            <option value="Active">Cancelled</option>
            <option value="Active">Pending Cancel</option>
            <option value="Active">Terminated</option>
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px">
            Policy Address*
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px max-width"
            id="address_line_2"
            autocomplete="off"
            ref={POLICY_ADDRS}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px ">
            Policy Company*
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px max-width"
            id="company"
            autocomplete="off"
            ref={POLICY_COMPANY}
          >
            {insurance_companies &&
              insurance_companies.map((company) => (
                <option value={company}>{company} </option>
              ))}
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            Effective Date*
          </label>
          <input
            type="date"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="effective_date"
            autocomplete="off"
            ref={EFFECTIVE_DATE}
          />
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Prem Total*
          </label>
          <input
            type="number"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            placeholder="$0.00"
            autocomplete="off"
            ref={TOTAL_PREM}
          />
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5px margin-top-5pxpx "
          >
            Primary Driver*
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px max-width"
            id="primary_driver"
            autocomplete="off"
            ref={primary_driver}
          >
            {driverData &&
              driverData.map((driver) => (
                <option value={driver.drivers_id}>{driver.full_name}</option>
              ))}
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx ">
            Expiration Date*
          </label>
          <input
            type="date"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            autocomplete="off"
            ref={EXP_DATE}
          />
          <label className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx ">
            Sales Type*
          </label>
          <select
             className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
             ref = {sales_type}
          >
            <option value= 'New Business'>New Business</option>
            <option value = 'Rewrite'>Rewrite</option>
            <option value = 'Cancel Rewrite'>Cancel Rewrite</option>
            <option value = 'Cross Sale'>Cross Sale</option>
          </select>
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Commissionable Prem
          </label>
          <input
            type="number"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            autocomplete="off"
            ref={comm_prem}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            BI Limits
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="bi_limits"
            autocomplete="off"
            ref={BI_LIMITS}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            PD Limits
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="pd_limits"
            autocomplete="off"
            ref={PD_LIMTIS}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            PIP Limits
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="pip_limits"
            autocomplete="off"
            ref={PIP_LIMITS}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            Guest Liability
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="guest_liability"
            autocomplete="off"
            ref={GUEST_LIABILITY}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            PIP Deductible
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width  "
            id="pip_deductible"
            autocomplete="off"
            ref={PIP_DEDUCTIBLE}
          />
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Date Bound
          </label>

          <span>{new Date().toLocaleDateString()}</span>
        </div>
        <button
          type="button"
          class="button-default green-submit-btn margin-top-10px max-width"
          onClick={() => preventDoubleClick(CREATE_NEW_MOTOR_POLICY_BTN)}
          disabled = {isButtonDisabled}
        >
          Add new policy
        </button>
      </div>
    </div>
  );
}

export default AddMotorcyclePolicyPopup;
